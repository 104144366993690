import React, { createContext, useState, useEffect } from "react";
import { GetInfoConfiguracion } from "../services/SettingService";
import * as signalR from "@microsoft/signalr";

const Context = createContext();

const AppProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [infoConfiguracion, setInfoConfiguracion] = useState(null);

  const [cbu, setCbu] = useState(null);
  const [alias, setAlias] = useState(null);

  const [aviso, setAviso] = useState(null);

  const [mantenimiento, setMantenimiento] = useState(null);

  const [modalCerrado, setModalCerrado] = useState(false);

  const [tipo, setTipo] = useState(null);
  const [subtipo, setSubtipo] = useState(null);
  const [destacado, setDestacado] = useState(null);
  const [promocion, setPromocion] = useState(null);
  const [laqueado, setLaqueado] = useState(null);
  const [categoria, setCategoria] = useState(null);
  const [material, setMaterial] = useState(null);

  const [activadoHeader, setActivadoHeader] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetInfoConfiguracion();
        setInfoConfiguracion(response);

        setCbu(response.cbu);
        setAlias(response.alias);

        setAviso(response.aviso);

        setMantenimiento(response.mantenimiento);

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .withUrl("https://mueblesmayo.somee.com/GeneralHub")
  //     .configureLogging(signalR.LogLevel.Information)
  //     .build();

  //   connection
  //     .start()
  //     .then(() => {
  //       // console.log("Conexión establecida con el servidor SignalR");
  //     })
  //     .catch((err) => console.error(err.toString()));

  //   connection.on("MensajeUpdateConfiguracion", async () => {
  //     try {
  //       const response = await GetInfoConfiguracion();
  //       setInfoConfiguracion(response);

  //       setCbu(response.cbu);
  //       setAlias(response.alias);

  //       setAviso(response.aviso);

  //       setMantenimiento(response.mantenimiento);

  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error(
  //         "Error al obtener la informacion de las configuraciones: " + error
  //       );
  //       setIsLoading(false);
  //     }
  //   });

  //   return () => {
  //     connection.stop();
  //   };
  // }, []);

  return (
    <Context.Provider
      value={{
        isLoading,
        infoConfiguracion,
        cbu,
        alias,
        aviso,
        mantenimiento,
        modalCerrado,
        setModalCerrado,
        tipo,
        subtipo,
        destacado,
        promocion,
        laqueado,
        categoria,
        material,
        activadoHeader,
        setTipo,
        setSubtipo,
        setDestacado,
        setPromocion,
        setLaqueado,
        setCategoria,
        setMaterial,
        setActivadoHeader,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, AppProvider };
