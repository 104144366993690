import React from "react";

import "./Loader.css";

// import loadergif from "../../assets/images/loader.gif";

const loader = () => {
  return (
    <div className="loading-container">
      <div className="spinner-border white" role="status">
        <span className="sr-only"></span>
      </div>
      {/* <img className='loader' src={loadergif} alt="Mapa Muebles Mayo" /> */}
    </div>
  );
};

export default loader;
